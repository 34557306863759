import { type IinitAuthResponse } from '@/services/auth.service'
import { useAuthStore } from '@/stores/auth.store'
import posthog from 'posthog-js'

const PROJECTAPIKEY = 'phc_DBkbsPSYbOmheeCje3FIkcLaJFLMByt6TCeiBbbssz6'
const CLIENTAPIHOST = 'https://eu.i.posthog.com'

const posthogPlugin = {
  install(app: any) {
    app.config.globalProperties.$posthog = posthog.init(PROJECTAPIKEY, {
      api_host: CLIENTAPIHOST
    })
  }
}

/**
 * this function identifies the user when they log in
 * @param user - user object
 */
const identifyPostHog = (user: IinitAuthResponse) => {
  const data = {
    username: user.email,
    occupation: user.occupation,
    'current plan': user.platformPlan
  }

  posthog.identify(user.email, data)
}
/**
 * this function captures the event
 * @param eventName - name of the event
 * @param data - data to be sent with the event
 */
const captureEvent = (eventName: string, data: any) => {
  try {
    const dataWithEmail = { ...data, email: useAuthStore().userEmail }
    posthog.capture(eventName, { ...dataWithEmail })
  } catch (error) {
    ;() => {}
  }
}

/**
 * resets the posthog instance when the user logs out
 */
const resetPostHog = () => {
  posthog.reset()
}

export default { posthogPlugin, identifyPostHog, captureEvent, resetPostHog }
